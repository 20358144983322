<template>
  <div class="footer_box">
    <div class="foot_cont">
      <div class="cont_menu">
        <p class="logo"><img src="@/assets/pc/deep_logo.png" alt=""></p>
        <div class="menu_cont">
          <ul>
            <li><router-link to="/form/companyForm">企业入驻</router-link></li>
            <li><router-link to="/form/modelWorkerForm">劳模认证</router-link></li>
            <li><router-link to="/form/unionForm">工会入驻</router-link></li>
          </ul>
          <ul>
            <!-- <li><router-link to="/">发起预约</router-link></li> -->
            <!-- <li><router-link to="/form/demandForm">发布需求</router-link></li> -->
            <!-- <li><router-link to="/">在线提问</router-link></li> -->
          </ul>
          <!-- <ul>
            <li><router-link to="/">活动介绍</router-link></li>
            <li><router-link to="/">联系我们</router-link></li>
            <li><router-link to="/">问题反馈</router-link></li>
          </ul> -->
        </div>
      </div>
      <div class="tips_text flex">
        <p>主办单位：大连市总工会</p>
        <p>建设单位：中工科创</p>
      </div>
      <div class="code_text">Copyright © 2023-2025 kjcxchina.com  All Rights Reserved | 京ICP备18023227号-1</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "header_box",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.footer_box {
  padding: 52px 0 33px;
  background: #262626;
  .foot_cont{
    width: 1200px;
    margin: 0 auto;
    .cont_menu{
      display: flex;
        padding-bottom: 53px;
        border-bottom:1px solid rgba(255,255,255,.1) ;

      .menu_cont{
        margin-left: 52px;
        display: flex;
        padding-left: 80px;
        border-left: 1px solid rgba(255,255,255,.1);
      
        ul{
          margin-right: 124px;
          li{
            margin-bottom: 14px;
            a{
              font-size: 15px;
              color: #FFFFFF;
            }
          }
          li:last-child{
            margin-bottom: 0;
          }
        }
        ul:last-child{
          margin-right: 0;
        }
      }
    }
    .tips_text{
      margin-top: 13px;
      p{
        font-size: 13px;
        color: #FFFFFF;
        margin-right: 74px;
      }
    }
    .code_text{
      margin-top: 13px;
      padding-top: 13px;
      border-top: 1px solid rgba(255,255,255,.1);
       font-size: 13px;
        color: #FFFFFF;
    }
  }
}

</style>
